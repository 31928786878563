import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GlobalVariable } from '../global';

import { UserApiService } from '../services/user-api.service';

@Component({
  selector: 'app-points-redeem',
  templateUrl: './points-redeem.page.html',
  styleUrls: ['./points-redeem.page.scss'],
})
export class PointsRedeemPage implements OnInit {
@Input('points')points
@Input('mypoints')mypoints
  constructor(public server:UserApiService,
     private modalController: ModalController,
     public globals:GlobalVariable,
     ) { }

  ngOnInit() {
  }
close(){
  this.modalController.dismiss()
}
changeVal(e){
  console.log(e.detail)
}
choose(p){
  if(p.availed){
    this.globals.presentToast("Already used...can't use twice");
    return
  }
  this.modalController.dismiss(p)

}
}
