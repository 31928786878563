import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GlobalVariable } from '../global';

@Component({
  selector: 'app-confirm-order',
  templateUrl: './confirm-order.page.html',
  styleUrls: ['./confirm-order.page.scss'],
})
export class ConfirmOrderPage implements OnInit {

  constructor(
     private modalController: ModalController,
     public globals:GlobalVariable,

  ) { }

  ngOnInit() {
  }
  close(){
this.modalController.dismiss({
      data:"false"
    })
  }


  proceed(){

    this.modalController.dismiss({
      data:"true"
    })
  }
}
