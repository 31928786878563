import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { GlobalVariable } from '../global';
import { LocalStorageService } from '../services/core/local-storage.service';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.page.html',
  styleUrls: ['./thankyou.page.scss'],
})
export class ThankyouPage implements OnInit {
  constructor(private modalController: ModalController,
    public globals:GlobalVariable,
    private nav:NavController,
    public storageservice:LocalStorageService,
    ) { 
    this.globals.couponAmount = 0;
    this.globals.giftcardData.amount=0;
    this.globals.points_availed=0;
    if(this.globals.guestLogin==true){
      this.globals.guestLogin=false;
      // localStorage.clear();
      this.storageservice.setFirstName('FirstName','');
      this.globals.udid=''

    }
    // this.globals.giftcardData=undefined;
  
    }

  ngOnInit() {
    
  }
close(){
this.nav.navigateRoot('/tabs/tabs/home');
  this.modalController.dismiss();
}
}
