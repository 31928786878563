import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GlobalVariable } from 'src/app/global';
import { UserApiService } from 'src/app/services/user-api.service';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LocalStorageService } from 'src/app/services/core/local-storage.service';
@Component({
  selector: 'app-add-review',
  templateUrl: './add-review.page.html',
  styleUrls: ['./add-review.page.scss'],
})
export class AddReviewPage implements OnInit {
  rating=0;
  udid: string;
  email: string;
  description=''
  // index1: any = 0;
  // array2: any[] = new Array();
  // visible: boolean = false;
  // data: any;
  // user_email: any;
  // title: any;
  // description: any;
  // rating: any;
  // bussiness: any;
  // submitAttempt: boolean = false;
  // // ReviewForm: FormGroup;
  // array: any[] = new Array();

  constructor( private modalController:ModalController,
    public globals:GlobalVariable,
    public server:UserApiService,
    private localStorageService:LocalStorageService,


    
     ) {
      this.udid= this.localStorageService.getUdId('Udid')
      this.email=this.localStorageService.getEmail('Email')

    // this.ReviewForm = formBilder.group({
    //   description: ['', Validators.compose([Validators.required])]
      
  // })
  // this.array.length = 5;
   }

  ngOnInit() {
  }

  changeRating(event){
    this.rating=event;
    console.log(this.rating)

 

    // console.log(event.detail)

  }
  save() {
    if(this.rating==0){
      this.globals.presentToast("Why not select rating first");
    }
    else{
      this.server.AddUserReview(this.email,this.description,this.rating).subscribe(data=>{
        console.log(data)
        if(data.status="Review added"){
          this.globals.presentToast("thanks for your feedback. your review hass been added. keep exploring");
          this.modalController.dismiss();
        }
      },err=>{
        this.globals.presentToast("failed to add")
      })
    }

}


// changeStar(index) {
//     if (!this.visible) {
//         this.index1 = index + 1;
//         this.visible = true;
//     } else {
//         this.visible = false;
//         this.index1 = index + 1;
//         this.visible = true;
//     }
// }

  closeModel() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }
}
