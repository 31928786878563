import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { GlobalVariable } from '../global';

@Component({
  selector: 'app-tip',
  templateUrl: './tip.page.html',
  styleUrls: ['./tip.page.scss'],
})
export class TipPage implements OnInit {
tip:number=0;
  tipType='percent';
  constructor(private modalController: ModalController,public globals:GlobalVariable, private alertController:AlertController) { }

  ngOnInit() {
  }
  close(){
    this.modalController.dismiss(0)
  }
  closeTip(){
    if(this.tip==0){
      this.globals.presentToast('Select Tip first')
    }
    else{
    this.modalController.dismiss(this.tip)
    }
    // {tip:this.tip,type:this.tipType}

  }
  segmentEvent(e){
    console.log(e.detail.value)
    if(e.detail.value!='custom'){
      this.tip=Number(e.detail.value)

    }
    else{
      this.custom()
      this.tipType='manual';
     
    }
  }
  async custom(){
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Custom Tip',
      inputs: [
      
        {
          name: 'tip',
          placeholder:'Enter $ tip',
          type: 'number',
          min: 1,
        },
       
    
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Add',
          handler: (data) => {
            console.log('Confirm Ok',data);
           this.tip= Number(data.tip)
          }
        }
      ]
    });
    alert.present()
  }
}
