import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GlobalVariable } from '../global';

@Component({
  selector: 'app-table-numpad',
  templateUrl: './table-numpad.page.html',
  styleUrls: ['./table-numpad.page.scss'],
})
export class TableNumpadPage implements OnInit {
  tableValue
  tableStampvalue: string="Enter Table"
  tableStamp: any=[]
  constructor(private modalController: ModalController,
    public globals:GlobalVariable) { }

  ngOnInit() {
  }
  close(){
    this.modalController.dismiss({table:this.tableStampvalue})

  }
  numberPress(value){
    console.log(value)
    
    if(value=='save'){
        console.log('saved')
        this.close()
        // this.enableTable = true;
    }
    else if (value=='cancel'){
        this.tableStamp=[]
        this.tableStampvalue='';
        console.log('cancelled')
    }
    else{
        this.tableStamp.push(value);
        let string= String(this.tableStamp)
        console.log(string);
      //   if(string.includes(',')){
          this.tableStampvalue=
          // string.replace(',','')
          string.replace(/,/gi, "");
      //   }
       
      
        console.log(this.tableStampvalue)

        
    }
}
}
