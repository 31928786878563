import { Component, OnInit, ViewChild } from '@angular/core';
import { IonDatetime, ModalController } from '@ionic/angular';
import { format, parseISO } from 'date-fns';
@Component({
  selector: 'app-date-time',
  templateUrl: './date-time.page.html',
  styleUrls: ['./date-time.page.scss'],
})
export class DateTimePage implements OnInit {
  @ViewChild(IonDatetime, { static: true }) datetime: IonDatetime;
  dateValue
  constructor(private modalController: ModalController) { }

  ngOnInit() {
  }

  confirm() {
    this.datetime.confirm();
  }
  
  reset() {
    this.datetime.reset();
  }
  save(){
    this.modalController.dismiss({date:this.dateValue})
  }
  formatDate(value: string) {
    console.log(value)
    return format(parseISO(value), 'MMM dd yyyy');
  }

}
