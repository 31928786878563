import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-photo-viewer',
  templateUrl: './photo-viewer.page.html',
  styleUrls: ['./photo-viewer.page.scss'],
})
export class PhotoViewerPage implements OnInit {
  @Input() image: any;
  options={
    zoom:true,
  }
  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {

  }
  ionViewWillEnter(){
   console.log( this.image)
  }
  close(){
    this.modalController.dismiss()
  }

}
